import React, { Component } from "react"
import Drawer from "@material-ui/core/Drawer"
import DrawerMenu from "./DrawerMenu"
import { COLORS } from "../../utils/Colors"
import { MdMenu } from "react-icons/md"
import logo from "../../images/logo.png"
import Tab from "./Tab"
import { Link } from "gatsby"

const menuItems = ["Home", "About", "Careers", "Contact"] //"Clients", , "Blog"
const menuItemLinks = {
  "Home": "/",
  "About": "/about",
  "Careers": "/careers",
  "Contact": "/contact",
  "Blog": "http://www.riktamtech.com/blog",
}
export default class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      drawerStatus: false,
      noOverlay: false,
    }
    this.openDrawer = this.openDrawer.bind(this)
    this.closeDrawer = this.closeDrawer.bind(this)
  }

  openDrawer() {
    this.setState({ drawerStatus: true })
  }

  closeDrawer() {
    this.setState({ drawerStatus: false })
  }

  render() {
    return (
      <div className="sticky-top" style={styles.container}>
        <div className="col-12">
          <div className="pl-0 d-inline-block col-12 col-md-6">
            <div className="pl-0 d-md-flex align-self-md-center col-12">
              <button
                className="d-inline-block d-md-none col-1"
                style={styles.menuButton}
                onClick={this.openDrawer}
              >
                <MdMenu color={COLORS.THEME_COLOR} style={styles.menuIcon} />
              </button>
              <span className="d-inline-block col-2" />
              <Link
                className="d-inline-block col-8 col-md-10"
                style={styles.company}
                to="/"
              >
                <img
                  className="img-fluid"
                  src={logo}
                  alt="Logo"
                  style={{ height: 35 }}
                />
              </Link>
            </div>
          </div>
          <div className="p-0 d-none d-md-inline-block col-6">
            <div className="col-md-12" style={styles.tabsView}>
              <div
                className="d-sm-flex justify-content-center col-md-12" //col-md-10
                style={{
                  position: "relative",
                  // backgroundColor: "red",
                  // bottom: 4,
                  top: 22,
                }}
              >
                <span className="col-lg-0" />
                {menuItems.map((item, index) => (
                  <Tab key={index} {...this.props} name={item} link={menuItemLinks[item]} />
                ))}
              </div>
            </div>
          </div>
        </div>
        <Drawer
          open={this.state.drawerStatus}
          onClose={this.closeDrawer}
          anchor="top"
          children={
            <DrawerMenu
              {...this.props}
              menuItems={menuItems}
              closeDrawer={this.closeDrawer}
            />
          }
        />
      </div>
    )
  }
}

const styles = {
  container: {
    height: 65,
    backgroundColor: COLORS.PRIMARY_COLOR,
    // backgroundColor: "red",
  },
  menuButton: {
    backgroundColor: COLORS.PRIMARY_COLOR,
    border: "none",
    color: COLORS.PRIMARY_COLOR,
    textAlign: "center",
    outline: "none",
    cursor: "pointer",
    // paddingTop: 18,
  },
  menuIcon: {
    fontSize: 22,
  },
  company: {
    // backgroundColor: "blue",
    fontSize: 32,
    fontWeight: "bold",
    left: 5,
    cursor: "pointer",
  },
  tabsView: {
    // backgroundColor: "green",
  },
}
