export const COLORS = {
  THEME_COLOR: "#4463fb",
  PRIMARY_COLOR: "#FFFFFF",
  SECONDARY_COLOR: "#000000",

  LIGHT_TEXT_COLOR: "#FFFFFF",
  DARK_TEXT_COLOR: "#56586c",
  SNACKBAR_SUCCESS: "green",
  SNACKBAR_FAIL: "tomato",
  SNACKBAR_WARNING: "orange",
  HIGHlIGHTED_TEXT: "#1b1d29",
  THEME_FONT: "#1E35A2",
  TAB_FONT_COLOR: "#464646",
  THEME_DETAIL_FONT: "#97a7f7",
  PROJECT_DESCRIPTION: "#898ca5",
  CONTACT_DETAILS_HEADING: "#555870",
  CONTACT_DETAILS: "#9B9DB1",
  DESCRIPTIONS: "#56586c",
  TYPE: "#7A7C92",
  TECHNOLOGIES_BORDER_COLOR: "#D1D5DF",

  // HOME_GRADIENT_BACKGROUND: "linear-gradient(to bottom, #E1F6F3, #FFFFFF)",
  PROJECTS_BACKGROUND: "#E8E9F1",
  INTRO_BACKGROUND: "#F2F3FA",
  INQUIRY_BACKGROUND: "#F5F6FB",
  INPUT_BORDER: "#DDE0E9",
  INPUT_BACKGROUND: "#FBFCFF",
  SCREEN_BACKGROUND: "#F5F6FB",
}
