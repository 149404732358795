import React, { Component } from "react"
import List from "@material-ui/core/List"
import { COLORS } from "../../utils/Colors"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
export default class DrawerMenu extends Component {
  render() {
    return (
      <List className="col-12">
        {this.props.menuItems.map((text, index) =>
          text === "Blog" ? (
            <a href="http://www.riktamtech.com/blog">
              <ListItem
                button
                key={index}
                onClick={() => {
                  this.props.closeDrawer()
                }}
                style={{
                  color:
                    true
                      ? COLORS.THEME_COLOR
                      : COLORS.DARK_TEXT_COLOR,
                }}
              >
                <ListItemText primary={text} />
              </ListItem>
            </a>
          ) : (
            <ListItem
              button
              key={index}
              onClick={() => {
                this.props.tabSelection(text)
                this.props.closeDrawer()
              }}
              style={{
                color:
                  true
                    ? COLORS.THEME_COLOR
                    : COLORS.DARK_TEXT_COLOR,
              }}
            >
              <ListItemText primary={text} />
            </ListItem>
          )
        )}
      </List>
    )
  }
}
