import React, { Component } from "react"
import { COLORS } from "../../utils/Colors"
import { Link } from "gatsby"

const today = new Date()
export default class Footer extends Component {
  getEachLink(label, to = "") {
    return (
      <div className="pl-0 d-inline-block col-6" style={styles.eachLink}>
        <Link to={to} style={styles.eachLink}>{label}</Link>
      </div>
    )
  }

  render() {
    return (
      <div className="" style={styles.container}>
        <div className="row col-12">
          <span className="col-0 col-md-1" />
          <div className="col-12 col-md-11">
            <div
              className="float-md-right col-12 col-md-6"
              style={{
                // float: "right",
                paddingRight: 0,
                paddingTop: 50,
                paddingBottom: 30,
              }}
            >
              <div className="col-12" style={{ paddingRight: 0 }}>
                <div
                  className="pl-0 text-md-right col-12"
                  style={{
                    // textAlign: "end",
                    // fontSize: 18,
                    paddingRight: 0,
                    fontFamily: "SFProHvy",
                  }}
                >
                  QUICK LINKS
                </div>
                <div
                  className="pl-0 text-md-right col-12"
                  style={{
                    //   textAlign: "end",
                    paddingTop: 20,
                    paddingRight: 0,
                    // fontSize: 14,
                  }}
                >
                  {/* {this.getEachLink("DEDICATED DEVELOPERS")}
                {this.getEachLink("TALENT MANAGEMENT")}
                {this.getEachLink("FOR SERVICE PROVIDERS")}
                {this.getEachLink("FOR ENTERPRISE")}
                {this.getEachLink("PRICING")}
                {this.getEachLink("FOR STARTUP")} */}
                  {this.getEachLink("Home", "/")}
                  {this.getEachLink("About", "/about")}
                  {this.getEachLink("Careers", "/careers")}
                  {/* {this.getEachLink("Clients")} */}
                  {this.getEachLink("Privacy Policy", "/privacy_policy")}
                  {this.getEachLink("Terms of Service", "/tos")}
                  {/* {this.getEachLink("Blog")} */}
                </div>
              </div>
            </div>
            <div
              className="pl-0 col-12 col-md-6"
              style={{ float: "left", paddingTop: 50, paddingBottom: 30 }}
            >
              <div
                className="col-12"
                style={{
                  textAlign: "start",
                  paddingRight: 0,
                  fontFamily: "SFProHvy",
                }}
              >
                ABOUT RIKTAM
              </div>
              <div
                className="col-12"
                style={{
                  textAlign: "start",
                  paddingTop: 20,
                  fontSize: 10,
                  paddingLeft: 0,
                }}
              >
                <div
                  className="col-12 col-md-8"
                  style={{
                    ...styles.eachLink,
                    color: COLORS.THEME_DETAIL_FONT,
                    fontSize: 14,
                  }}
                >
                  Engineers at Riktam build Web and Mobile Applications using
                  the latest technologies, for startups around the world. Our
                  work spans online marketplaces, retail, logistics, sports,
                  dev-ops, ad and content serving, data and visualisation.
                </div>
                <div
                  className="col-12"
                  style={{
                    fontSize: 10,
                    paddingTop: 50,
                    color: COLORS.THEME_FONT,
                    fontFamily: "SFProMed",
                  }}
                >
                  Copyright {today.getFullYear()} - Riktam Technology
                  Consulting. All rights reserved.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

var styles = {
  container: {
    backgroundColor: COLORS.THEME_COLOR,
    color: COLORS.LIGHT_TEXT_COLOR,
  },
  eachLink: {
    paddingTop: 10,
    paddingRight: 0,
    fontFamily: "SFProMed",
    cursor: "pointer",
    color: COLORS.PRIMARY_COLOR,
    textDecoration: "none",
  },
}
