import React, { Component } from "react"
import { Helmet } from "react-helmet"
import "../css/Common.css"
import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
export default function Layout({ children }) {
    return (
        <div style={{ fontFamily: "SFProReg" }}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Riktam</title>
                <link
                    rel="stylesheet"
                    href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css"
                    integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh"
                    crossorigin="anonymous"
                />
            </Helmet>
            <Header />
            {children}
            <Footer />
        </div>
    )
}